.dxy-input {
    border: none;
    font-size: var(--input-font-size);
    flex-grow: 1;
    padding: var(--input-padding-xs) 0px;
    background: transparent;
    line-height: var(--input-line-height);

    &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &:not(input, textarea):empty {
        height: calc(var(--input-line-height) + 2 * var(--input-padding-xs));
    }
}
