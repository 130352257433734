@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?1spo97');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?1spo97#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?1spo97') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?1spo97') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?1spo97##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="glyph-"], [class*=" glyph-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyph-logo-dg {
  &:before {
    content: $glyph-logo-dg; 
  }
}
.glyph-home {
  &:before {
    content: $glyph-home; 
  }
}
.glyph-search {
  &:before {
    content: $glyph-search; 
  }
}
.glyph-object-preview {
  &:before {
    content: $glyph-object-preview; 
  }
}
.glyph-my-work {
  &:before {
    content: $glyph-my-work; 
  }
}
.glyph-delete {
  &:before {
    content: $glyph-delete; 
  }
}
.glyph-jirabug {
  &:before {
    content: $glyph-jirabug; 
  }
}
.glyph-insight {
  &:before {
    content: $glyph-insight; 
  }
}
.glyph-bulb {
  &:before {
    content: $glyph-bulb; 
  }
}
.glyph-email {
  &:before {
    content: $glyph-email; 
  }
}
.glyph-extension {
  &:before {
    content: $glyph-extension; 
  }
}
.glyph-plugins {
  &:before {
    content: $glyph-plugins; 
  }
}
.glyph-file-copy {
  &:before {
    content: $glyph-file-copy; 
  }
}
.glyph-edit {
  &:before {
    content: $glyph-edit; 
  }
}
.glyph-mass-edit {
  &:before {
    content: $glyph-mass-edit; 
  }
}
.glyph-edit-screen {
  &:before {
    content: $glyph-edit-screen; 
  }
}
.glyph-screen {
  &:before {
    content: $glyph-screen; 
  }
}
.glyph-features {
  &:before {
    content: $glyph-features; 
  }
}
.glyph-layout-split {
  &:before {
    content: $glyph-layout-split; 
  }
}
.glyph-object-page {
  &:before {
    content: $glyph-object-page; 
  }
}
.glyph-compare-item {
  &:before {
    content: $glyph-compare-item; 
  }
}
.glyph-desktop {
  &:before {
    content: $glyph-desktop; 
  }
}
.glyph-online {
  &:before {
    content: $glyph-online; 
  }
}
.glyph-keyboard {
  &:before {
    content: $glyph-keyboard; 
  }
}
.glyph-hyperlink {
  &:before {
    content: $glyph-hyperlink; 
  }
}
.glyph-shortcut {
  &:before {
    content: $glyph-shortcut; 
  }
}
.glyph-send {
  &:before {
    content: $glyph-send; 
  }
}
.glyph-share {
  &:before {
    content: $glyph-share; 
  }
}
.glyph-mapping {
  &:before {
    content: $glyph-mapping; 
  }
}
.glyph-notification {
  &:before {
    content: $glyph-notification; 
  }
}
.glyph-no-notification {
  &:before {
    content: $glyph-no-notification; 
  }
}
.glyph-like-up {
  &:before {
    content: $glyph-like-up; 
  }
}
.glyph-like-down {
  &:before {
    content: $glyph-like-down; 
  }
}
.glyph-upload {
  &:before {
    content: $glyph-upload; 
  }
}
.glyph-import {
  &:before {
    content: $glyph-import; 
  }
}
.glyph-download {
  &:before {
    content: $glyph-download; 
  }
}
.glyph-export {
  &:before {
    content: $glyph-export; 
  }
}
.glyph-live {
  &:before {
    content: $glyph-live; 
  }
}
.glyph-dashboard {
  &:before {
    content: $glyph-dashboard; 
  }
}
.glyph-quality {
  &:before {
    content: $glyph-quality; 
  }
}
.glyph-campaign {
  &:before {
    content: $glyph-campaign; 
  }
}
.glyph-content-view-module-2 {
  &:before {
    content: $glyph-content-view-module-2; 
  }
}
.glyph-splitter {
  &:before {
    content: $glyph-splitter; 
  }
}
.glyph-more {
  &:before {
    content: $glyph-more; 
  }
}
.glyph-drag {
  &:before {
    content: $glyph-drag; 
  }
}
.glyph-dots-nine {
  &:before {
    content: $glyph-dots-nine; 
  }
}
.glyph-bulb1 {
  &:before {
    content: $glyph-bulb1; 
  }
}
.glyph-marker {
  &:before {
    content: $glyph-marker; 
  }
}
.glyph-pending {
  &:before {
    content: $glyph-pending; 
  }
}
.glyph-save {
  &:before {
    content: $glyph-save; 
  }
}
.glyph-save1 {
  &:before {
    content: $glyph-save1; 
  }
}
.glyph-settings {
  &:before {
    content: $glyph-settings; 
  }
}
.glyph-slider-2 {
  &:before {
    content: $glyph-slider-2; 
  }
}
.glyph-settings1 {
  &:before {
    content: $glyph-settings1; 
  }
}
.glyph-ai {
  &:before {
    content: $glyph-ai; 
  }
}
.glyph-robot {
  &:before {
    content: $glyph-robot; 
  }
}
.glyph-robot2 {
  &:before {
    content: $glyph-robot2; 
  }
}
.glyph-robot2-off {
  &:before {
    content: $glyph-robot2-off; 
  }
}
.glyph-link-obj-ai {
  &:before {
    content: $glyph-link-obj-ai; 
  }
}
.glyph-metabot-links {
  &:before {
    content: $glyph-metabot-links; 
  }
}
.glyph-text-ai {
  &:before {
    content: $glyph-text-ai; 
  }
}
.glyph-language-ai {
  &:before {
    content: $glyph-language-ai; 
  }
}
.glyph-redo-ai {
  &:before {
    content: $glyph-redo-ai; 
  }
}
.glyph-glossary-ai {
  &:before {
    content: $glyph-glossary-ai; 
  }
}
.glyph-metabot-glossary {
  &:before {
    content: $glyph-metabot-glossary; 
  }
}
.glyph-profile {
  &:before {
    content: $glyph-profile; 
  }
}
.glyph-person {
  &:before {
    content: $glyph-person; 
  }
}
.glyph-invite-user {
  &:before {
    content: $glyph-invite-user; 
  }
}
.glyph-user-unlock {
  &:before {
    content: $glyph-user-unlock; 
  }
}
.glyph-team {
  &:before {
    content: $glyph-team; 
  }
}
.glyph-team-share {
  &:before {
    content: $glyph-team-share; 
  }
}
.glyph-tools-publish {
  &:before {
    content: $glyph-tools-publish; 
  }
}
.glyph-personal-doc {
  &:before {
    content: $glyph-personal-doc; 
  }
}
.glyph-admin {
  &:before {
    content: $glyph-admin; 
  }
}
.glyph-team-owner {
  &:before {
    content: $glyph-team-owner; 
  }
}
.glyph-sort {
  &:before {
    content: $glyph-sort; 
  }
}
.glyph-assets {
  &:before {
    content: $glyph-assets; 
  }
}
.glyph-view-list {
  &:before {
    content: $glyph-view-list; 
  }
}
.glyph-view-list1 {
  &:before {
    content: $glyph-view-list1; 
  }
}
.glyph-collection-static {
  &:before {
    content: $glyph-collection-static; 
  }
}
.glyph-view-flat {
  &:before {
    content: $glyph-view-flat; 
  }
}
.glyph-view-tree {
  &:before {
    content: $glyph-view-tree; 
  }
}
.glyph-hierarchy {
  &:before {
    content: $glyph-hierarchy; 
  }
}
.glyph-descendants {
  &:before {
    content: $glyph-descendants; 
  }
}
.glyph-valuelist {
  &:before {
    content: $glyph-valuelist; 
  }
}
.glyph-move-child {
  &:before {
    content: $glyph-move-child; 
  }
}
.glyph-txt-bullets {
  &:before {
    content: $glyph-txt-bullets; 
  }
}
.glyph-txt-num {
  &:before {
    content: $glyph-txt-num; 
  }
}
.glyph-txt-align {
  &:before {
    content: $glyph-txt-align; 
  }
}
.glyph-align-left {
  &:before {
    content: $glyph-align-left; 
  }
}
.glyph-align-right {
  &:before {
    content: $glyph-align-right; 
  }
}
.glyph-align-center {
  &:before {
    content: $glyph-align-center; 
  }
}
.glyph-align-top {
  &:before {
    content: $glyph-align-top; 
  }
}
.glyph-align-middle {
  &:before {
    content: $glyph-align-middle; 
  }
}
.glyph-align-bottom {
  &:before {
    content: $glyph-align-bottom; 
  }
}
.glyph-grid-columns {
  &:before {
    content: $glyph-grid-columns; 
  }
}
.glyph-unsorted {
  &:before {
    content: $glyph-unsorted; 
  }
}
.glyph-asc-sort {
  &:before {
    content: $glyph-asc-sort; 
  }
}
.glyph-desc-sort {
  &:before {
    content: $glyph-desc-sort; 
  }
}
.glyph-arrow-drop-up {
  &:before {
    content: $glyph-arrow-drop-up; 
  }
}
.glyph-arrow-drop-down {
  &:before {
    content: $glyph-arrow-drop-down; 
  }
}
.glyph-collapse {
  &:before {
    content: $glyph-collapse; 
  }
}
.glyph-arrow-drop-right {
  &:before {
    content: $glyph-arrow-drop-right; 
  }
}
.glyph-uncollapse {
  &:before {
    content: $glyph-uncollapse; 
  }
}
.glyph-order-by {
  &:before {
    content: $glyph-order-by; 
  }
}
.glyph-arrow-down {
  &:before {
    content: $glyph-arrow-down; 
  }
}
.glyph-arrow-up {
  &:before {
    content: $glyph-arrow-up; 
  }
}
.glyph-arrow-left {
  &:before {
    content: $glyph-arrow-left; 
  }
}
.glyph-arrow-right {
  &:before {
    content: $glyph-arrow-right; 
  }
}
.glyph-arrow-down-right {
  &:before {
    content: $glyph-arrow-down-right; 
  }
}
.glyph-arrow-up-left {
  &:before {
    content: $glyph-arrow-up-left; 
  }
}
.glyph-expand-horizontal {
  &:before {
    content: $glyph-expand-horizontal; 
  }
}
.glyph-expand-vertical {
  &:before {
    content: $glyph-expand-vertical; 
  }
}
.glyph-fullscreen-expand {
  &:before {
    content: $glyph-fullscreen-expand; 
  }
}
.glyph-fullscreen-contract {
  &:before {
    content: $glyph-fullscreen-contract; 
  }
}
.glyph-expand-all {
  &:before {
    content: $glyph-expand-all; 
  }
}
.glyph-glyph_dgg2 {
  &:before {
    content: $glyph-glyph_dgg2; 
  }
}
.glyph-shrink-all {
  &:before {
    content: $glyph-shrink-all; 
  }
}
.glyph-glyph_ddg2 {
  &:before {
    content: $glyph-glyph_ddg2; 
  }
}
.glyph-shrink-vertical {
  &:before {
    content: $glyph-shrink-vertical; 
  }
}
.glyph-filter-empty {
  &:before {
    content: $glyph-filter-empty; 
  }
}
.glyph-filter-filled {
  &:before {
    content: $glyph-filter-filled; 
  }
}
.glyph-chat-comment {
  &:before {
    content: $glyph-chat-comment; 
  }
}
.glyph-comment1 {
  &:before {
    content: $glyph-comment1; 
  }
}
.glyph-comment-add {
  &:before {
    content: $glyph-comment-add; 
  }
}
.glyph-comment-subtract {
  &:before {
    content: $glyph-comment-subtract; 
  }
}
.glyph-comment-duo {
  &:before {
    content: $glyph-comment-duo; 
  }
}
.glyph-comment {
  &:before {
    content: $glyph-comment; 
  }
}
.glyph-comment-edit {
  &:before {
    content: $glyph-comment-edit; 
  }
}
.glyph-chat-question {
  &:before {
    content: $glyph-chat-question; 
  }
}
.glyph-file-tasks-add {
  &:before {
    content: $glyph-file-tasks-add; 
  }
}
.glyph-file-tasks-alert {
  &:before {
    content: $glyph-file-tasks-alert; 
  }
}
.glyph-file-tasks-block {
  &:before {
    content: $glyph-file-tasks-block; 
  }
}
.glyph-file-tasks-close {
  &:before {
    content: $glyph-file-tasks-close; 
  }
}
.glyph-file-tasks-check {
  &:before {
    content: $glyph-file-tasks-check; 
  }
}
.glyph-file-tasks-edit {
  &:before {
    content: $glyph-file-tasks-edit; 
  }
}
.glyph-file-tasks-subtract {
  &:before {
    content: $glyph-file-tasks-subtract; 
  }
}
.glyph-file-tasks-view {
  &:before {
    content: $glyph-file-tasks-view; 
  }
}
.glyph-clock-filled {
  &:before {
    content: $glyph-clock-filled; 
  }
}
.glyph-invalidation {
  &:before {
    content: $glyph-invalidation; 
  }
}
.glyph-in-validation {
  &:before {
    content: $glyph-in-validation; 
  }
}
.glyph-redo-filled {
  &:before {
    content: $glyph-redo-filled; 
  }
}
.glyph-inrevision {
  &:before {
    content: $glyph-inrevision; 
  }
}
.glyph-in-revision {
  &:before {
    content: $glyph-in-revision; 
  }
}
.glyph-question-filled {
  &:before {
    content: $glyph-question-filled; 
  }
}
.glyph-proposed {
  &:before {
    content: $glyph-proposed; 
  }
}
.glyph-alert-warning {
  &:before {
    content: $glyph-alert-warning; 
  }
}
.glyph-alert-critical {
  &:before {
    content: $glyph-alert-critical; 
  }
}
.glyph-close-circle-filled {
  &:before {
    content: $glyph-close-circle-filled; 
  }
}
.glyph-obsolete {
  &:before {
    content: $glyph-obsolete; 
  }
}
.glyph-check-circle-filled {
  &:before {
    content: $glyph-check-circle-filled; 
  }
}
.glyph-validated {
  &:before {
    content: $glyph-validated; 
  }
}
.glyph-info-circle-filled {
  &:before {
    content: $glyph-info-circle-filled; 
  }
}
.glyph-checkbox-checked-filled {
  &:before {
    content: $glyph-checkbox-checked-filled; 
  }
}
.glyph-checkbox-checked {
  &:before {
    content: $glyph-checkbox-checked; 
  }
}
.glyph-checked3 {
  &:before {
    content: $glyph-checked3; 
  }
}
.glyph-checkbox-indeterminate-filled {
  &:before {
    content: $glyph-checkbox-indeterminate-filled; 
  }
}
.glyph-checkbox-indeterminate {
  &:before {
    content: $glyph-checkbox-indeterminate; 
  }
}
.glyph-circle-filled {
  &:before {
    content: $glyph-circle-filled; 
  }
}
.glyph-color {
  &:before {
    content: $glyph-color; 
  }
}
.glyph-alert-unknown {
  &:before {
    content: $glyph-alert-unknown; 
  }
}
.glyph-radiobutton-selected {
  &:before {
    content: $glyph-radiobutton-selected; 
  }
}
.glyph-radiobutton-indeterminate {
  &:before {
    content: $glyph-radiobutton-indeterminate; 
  }
}
.glyph-star-fav {
  &:before {
    content: $glyph-star-fav; 
  }
}
.glyph-star {
  &:before {
    content: $glyph-star; 
  }
}
.glyph-checkbox-unchecked {
  &:before {
    content: $glyph-checkbox-unchecked; 
  }
}
.glyph-checkbox-checked-stroke {
  &:before {
    content: $glyph-checkbox-checked-stroke; 
  }
}
.glyph-checkbox {
  &:before {
    content: $glyph-checkbox; 
  }
}
.glyph-checked2 {
  &:before {
    content: $glyph-checked2; 
  }
}
.glyph-radiobutton-unselected {
  &:before {
    content: $glyph-radiobutton-unselected; 
  }
}
.glyph-circle {
  &:before {
    content: $glyph-circle; 
  }
}
.glyph-color-white {
  &:before {
    content: $glyph-color-white; 
  }
}
.glyph-circle-dashed {
  &:before {
    content: $glyph-circle-dashed; 
  }
}
.glyph-color-multi {
  &:before {
    content: $glyph-color-multi; 
  }
}
.glyph-add {
  &:before {
    content: $glyph-add; 
  }
}
.glyph-minus {
  &:before {
    content: $glyph-minus; 
  }
}
.glyph-check {
  &:before {
    content: $glyph-check; 
  }
}
.glyph-cancelsearch {
  &:before {
    content: $glyph-cancelsearch; 
  }
}
.glyph-plus-circle {
  &:before {
    content: $glyph-plus-circle; 
  }
}
.glyph-glyph_dggg {
  &:before {
    content: $glyph-glyph_dggg; 
  }
}
.glyph-plus-circle-small {
  &:before {
    content: $glyph-plus-circle-small; 
  }
}
.glyph-info {
  &:before {
    content: $glyph-info; 
  }
}
.glyph-minus-circle-small {
  &:before {
    content: $glyph-minus-circle-small; 
  }
}
.glyph-minus-circle {
  &:before {
    content: $glyph-minus-circle; 
  }
}
.glyph-close-circle {
  &:before {
    content: $glyph-close-circle; 
  }
}
.glyph-close-circle-small {
  &:before {
    content: $glyph-close-circle-small; 
  }
}
.glyph-check-circle-1 {
  &:before {
    content: $glyph-check-circle-1; 
  }
}
.glyph-mandatory {
  &:before {
    content: $glyph-mandatory; 
  }
}
.glyph-clock {
  &:before {
    content: $glyph-clock; 
  }
}
.glyph-activity-log {
  &:before {
    content: $glyph-activity-log; 
  }
}
.glyph-help {
  &:before {
    content: $glyph-help; 
  }
}
.glyph-none {
  &:before {
    content: $glyph-none; 
  }
}
.glyph-logout {
  &:before {
    content: $glyph-logout; 
  }
}
.glyph-spinner {
  &:before {
    content: $glyph-spinner; 
  }
}
.glyph-spinner2 {
  &:before {
    content: $glyph-spinner2; 
  }
}
.glyph-recent {
  &:before {
    content: $glyph-recent; 
  }
}
.glyph-revert {
  &:before {
    content: $glyph-revert; 
  }
}
.glyph-reset {
  &:before {
    content: $glyph-reset; 
  }
}
.glyph-redo2 {
  &:before {
    content: $glyph-redo2; 
  }
}
.glyph-reload {
  &:before {
    content: $glyph-reload; 
  }
}
.glyph-sync {
  &:before {
    content: $glyph-sync; 
  }
}
.glyph-inverse {
  &:before {
    content: $glyph-inverse; 
  }
}
.glyph-undo {
  &:before {
    content: $glyph-undo; 
  }
}
.glyph-undo2 {
  &:before {
    content: $glyph-undo2; 
  }
}
.glyph-warning {
  &:before {
    content: $glyph-warning; 
  }
}
.glyph-lock {
  &:before {
    content: $glyph-lock; 
  }
}
.glyph-lock2 {
  &:before {
    content: $glyph-lock2; 
  }
}
.glyph-unlock {
  &:before {
    content: $glyph-unlock; 
  }
}
.glyph-unlock2 {
  &:before {
    content: $glyph-unlock2; 
  }
}
.glyph-watch-on {
  &:before {
    content: $glyph-watch-on; 
  }
}
.glyph-show {
  &:before {
    content: $glyph-show; 
  }
}
.glyph-hide {
  &:before {
    content: $glyph-hide; 
  }
}
.glyph-view-more {
  &:before {
    content: $glyph-view-more; 
  }
}
.glyph-unlink {
  &:before {
    content: $glyph-unlink; 
  }
}
.glyph-link-broken {
  &:before {
    content: $glyph-link-broken; 
  }
}
.glyph-link {
  &:before {
    content: $glyph-link; 
  }
}
.glyph-link1 {
  &:before {
    content: $glyph-link1; 
  }
}
.glyph-date {
  &:before {
    content: $glyph-date; 
  }
}
.glyph-copy-compared-field {
  &:before {
    content: $glyph-copy-compared-field; 
  }
}
.glyph-project {
  &:before {
    content: $glyph-project; 
  }
}
.glyph-organization {
  &:before {
    content: $glyph-organization; 
  }
}
.glyph-datamap {
  &:before {
    content: $glyph-datamap; 
  }
}
.glyph-visual-analysis {
  &:before {
    content: $glyph-visual-analysis; 
  }
}
.glyph-analysis {
  &:before {
    content: $glyph-analysis; 
  }
}
.glyph-diagram1 {
  &:before {
    content: $glyph-diagram1; 
  }
}
.glyph-lineage-explore {
  &:before {
    content: $glyph-lineage-explore; 
  }
}
.glyph-caret-double-left {
  &:before {
    content: $glyph-caret-double-left; 
  }
}
.glyph-caret-double-right {
  &:before {
    content: $glyph-caret-double-right; 
  }
}
.glyph-caret-left {
  &:before {
    content: $glyph-caret-left; 
  }
}
.glyph-caret-right {
  &:before {
    content: $glyph-caret-right; 
  }
}
.glyph-density-high {
  &:before {
    content: $glyph-density-high; 
  }
}
.glyph-density-medium {
  &:before {
    content: $glyph-density-medium; 
  }
}
.glyph-density-low {
  &:before {
    content: $glyph-density-low; 
  }
}
.glyph-density {
  &:before {
    content: $glyph-density; 
  }
}
.glyph-text1 {
  &:before {
    content: $glyph-text1; 
  }
}
.glyph-relation {
  &:before {
    content: $glyph-relation; 
  }
}
.glyph-unrelation {
  &:before {
    content: $glyph-unrelation; 
  }
}
.glyph-fk {
  &:before {
    content: $glyph-fk; 
  }
}
.glyph-pk {
  &:before {
    content: $glyph-pk; 
  }
}
.glyph-diag-arrow-01 {
  &:before {
    content: $glyph-diag-arrow-01; 
  }
}
.glyph-diag-arrow-02 {
  &:before {
    content: $glyph-diag-arrow-02; 
  }
}
.glyph-diag-arrow-03 {
  &:before {
    content: $glyph-diag-arrow-03; 
  }
}
.glyph-diag-arrow-04 {
  &:before {
    content: $glyph-diag-arrow-04; 
  }
}
.glyph-direction-after {
  &:before {
    content: $glyph-direction-after; 
  }
}
.glyph-direction-before {
  &:before {
    content: $glyph-direction-before; 
  }
}
.glyph-direction-both {
  &:before {
    content: $glyph-direction-both; 
  }
}
.glyph-flip-horizontal {
  &:before {
    content: $glyph-flip-horizontal; 
  }
}
.glyph-diagram {
  &:before {
    content: $glyph-diagram; 
  }
}
.glyph-frame {
  &:before {
    content: $glyph-frame; 
  }
}
.glyph-golden-link-off {
  &:before {
    content: $glyph-golden-link-off; 
  }
}
.glyph-golden-link {
  &:before {
    content: $glyph-golden-link; 
  }
}
.glyph-golden-off {
  &:before {
    content: $glyph-golden-off; 
  }
}
.glyph-golden {
  &:before {
    content: $glyph-golden; 
  }
}
.glyph-fusion {
  &:before {
    content: $glyph-fusion; 
  }
}
.glyph-grid {
  &:before {
    content: $glyph-grid; 
  }
}
.glyph-grid-magnetism-off {
  &:before {
    content: $glyph-grid-magnetism-off; 
  }
}
.glyph-grid-magnetism-on {
  &:before {
    content: $glyph-grid-magnetism-on; 
  }
}
.glyph-group {
  &:before {
    content: $glyph-group; 
  }
}
.glyph-hand-peace {
  &:before {
    content: $glyph-hand-peace; 
  }
}
.glyph-hand {
  &:before {
    content: $glyph-hand; 
  }
}
.glyph-selection {
  &:before {
    content: $glyph-selection; 
  }
}
.glyph-handle-simple {
  &:before {
    content: $glyph-handle-simple; 
  }
}
.glyph-handle-right {
  &:before {
    content: $glyph-handle-right; 
  }
}
.glyph-handle-left {
  &:before {
    content: $glyph-handle-left; 
  }
}
.glyph-maxi-obj {
  &:before {
    content: $glyph-maxi-obj; 
  }
}
.glyph-medium-obj {
  &:before {
    content: $glyph-medium-obj; 
  }
}
.glyph-mini-obj {
  &:before {
    content: $glyph-mini-obj; 
  }
}
.glyph-nano-box {
  &:before {
    content: $glyph-nano-box; 
  }
}
.glyph-more-obj {
  &:before {
    content: $glyph-more-obj; 
  }
}
.glyph-light-links {
  &:before {
    content: $glyph-light-links; 
  }
}
.glyph-full-links {
  &:before {
    content: $glyph-full-links; 
  }
}
.glyph-lineage-group {
  &:before {
    content: $glyph-lineage-group; 
  }
}
.glyph-lineage-ungroup {
  &:before {
    content: $glyph-lineage-ungroup; 
  }
}
.glyph-lineage-static {
  &:before {
    content: $glyph-lineage-static; 
  }
}
.glyph-lineage {
  &:before {
    content: $glyph-lineage; 
  }
}
.glyph-caption-outside {
  &:before {
    content: $glyph-caption-outside; 
  }
}
.glyph-caption-inside {
  &:before {
    content: $glyph-caption-inside; 
  }
}
.glyph-photo {
  &:before {
    content: $glyph-photo; 
  }
}
.glyph-eraser {
  &:before {
    content: $glyph-eraser; 
  }
}
.glyph-location-map {
  &:before {
    content: $glyph-location-map; 
  }
}
.glyph-note {
  &:before {
    content: $glyph-note; 
  }
}
.glyph-postit {
  &:before {
    content: $glyph-postit; 
  }
}
.glyph-txt-style {
  &:before {
    content: $glyph-txt-style; 
  }
}
.glyph-txt-title {
  &:before {
    content: $glyph-txt-title; 
  }
}
.glyph-txt-size {
  &:before {
    content: $glyph-txt-size; 
  }
}
.glyph-txt-quote {
  &:before {
    content: $glyph-txt-quote; 
  }
}
.glyph-txt-italic {
  &:before {
    content: $glyph-txt-italic; 
  }
}
.glyph-txt-bold {
  &:before {
    content: $glyph-txt-bold; 
  }
}
.glyph-txt-underline {
  &:before {
    content: $glyph-txt-underline; 
  }
}
.glyph-txt-strike {
  &:before {
    content: $glyph-txt-strike; 
  }
}
.glyph-txt-code {
  &:before {
    content: $glyph-txt-code; 
  }
}
.glyph-txt-super {
  &:before {
    content: $glyph-txt-super; 
  }
}
.glyph-txt-infra {
  &:before {
    content: $glyph-txt-infra; 
  }
}
.glyph-object {
  &:before {
    content: $glyph-object; 
  }
}
.glyph-zoom-reset {
  &:before {
    content: $glyph-zoom-reset; 
  }
}
.glyph-datacatalog {
  &:before {
    content: $glyph-datacatalog; 
  }
}
.glyph-software {
  &:before {
    content: $glyph-software; 
  }
}
.glyph-software-dashboard {
  &:before {
    content: $glyph-software-dashboard; 
  }
}
.glyph-process {
  &:before {
    content: $glyph-process; 
  }
}
.glyph-dataset {
  &:before {
    content: $glyph-dataset; 
  }
}
.glyph-opendataset {
  &:before {
    content: $glyph-opendataset; 
  }
}
.glyph-software-application {
  &:before {
    content: $glyph-software-application; 
  }
}
.glyph-software-screen {
  &:before {
    content: $glyph-software-screen; 
  }
}
.glyph-report {
  &:before {
    content: $glyph-report; 
  }
}
.glyph-algorithm {
  &:before {
    content: $glyph-algorithm; 
  }
}
.glyph-usage-component {
  &:before {
    content: $glyph-usage-component; 
  }
}
.glyph-usage-field {
  &:before {
    content: $glyph-usage-field; 
  }
}
.glyph-glossary {
  &:before {
    content: $glyph-glossary; 
  }
}
.glyph-glossary2 {
  &:before {
    content: $glyph-glossary2; 
  }
}
.glyph-sub-domain {
  &:before {
    content: $glyph-sub-domain; 
  }
}
.glyph-domain {
  &:before {
    content: $glyph-domain; 
  }
}
.glyph-domain-group {
  &:before {
    content: $glyph-domain-group; 
  }
}
.glyph-term {
  &:before {
    content: $glyph-term; 
  }
}
.glyph-indicator-group {
  &:before {
    content: $glyph-indicator-group; 
  }
}
.glyph-indicator {
  &:before {
    content: $glyph-indicator; 
  }
}
.glyph-universe {
  &:before {
    content: $glyph-universe; 
  }
}
.glyph-dimension-group {
  &:before {
    content: $glyph-dimension-group; 
  }
}
.glyph-concept {
  &:before {
    content: $glyph-concept; 
  }
}
.glyph-value {
  &:before {
    content: $glyph-value; 
  }
}
.glyph-sub-dimension {
  &:before {
    content: $glyph-sub-dimension; 
  }
}
.glyph-dimension {
  &:before {
    content: $glyph-dimension; 
  }
}
.glyph-reference-data {
  &:before {
    content: $glyph-reference-data; 
  }
}
.glyph-rule-governance {
  &:before {
    content: $glyph-rule-governance; 
  }
}
.glyph-rule-lifecycle {
  &:before {
    content: $glyph-rule-lifecycle; 
  }
}
.glyph-catalog {
  &:before {
    content: $glyph-catalog; 
  }
}
.glyph-source {
  &:before {
    content: $glyph-source; 
  }
}
.glyph-model {
  &:before {
    content: $glyph-model; 
  }
}
.glyph-table {
  &:before {
    content: $glyph-table; 
  }
}
.glyph-view {
  &:before {
    content: $glyph-view; 
  }
}
.glyph-sub-structure {
  &:before {
    content: $glyph-sub-structure; 
  }
}
.glyph-columnview {
  &:before {
    content: $glyph-columnview; 
  }
}
.glyph-datafield {
  &:before {
    content: $glyph-datafield; 
  }
}
.glyph-filestore {
  &:before {
    content: $glyph-filestore; 
  }
}
.glyph-nosql {
  &:before {
    content: $glyph-nosql; 
  }
}
.glyph-tagbase {
  &:before {
    content: $glyph-tagbase; 
  }
}
.glyph-equipment {
  &:before {
    content: $glyph-equipment; 
  }
}
.glyph-tag {
  &:before {
    content: $glyph-tag; 
  }
}
.glyph-database {
  &:before {
    content: $glyph-database; 
  }
}
.glyph-container {
  &:before {
    content: $glyph-container; 
  }
}
.glyph-containerfolder {
  &:before {
    content: $glyph-containerfolder; 
  }
}
.glyph-document {
  &:before {
    content: $glyph-document; 
  }
}
.glyph-file1 {
  &:before {
    content: $glyph-file1; 
  }
}
.glyph-governance {
  &:before {
    content: $glyph-governance; 
  }
}
.glyph-rule-quality {
  &:before {
    content: $glyph-rule-quality; 
  }
}
.glyph-policy {
  &:before {
    content: $glyph-policy; 
  }
}
.glyph-rule-security {
  &:before {
    content: $glyph-rule-security; 
  }
}
.glyph-rule-ethics {
  &:before {
    content: $glyph-rule-ethics; 
  }
}
.glyph-rule {
  &:before {
    content: $glyph-rule; 
  }
}
.glyph-rule-privacy {
  &:before {
    content: $glyph-rule-privacy; 
  }
}
.glyph-rules-group {
  &:before {
    content: $glyph-rules-group; 
  }
}
.glyph-policy-group {
  &:before {
    content: $glyph-policy-group; 
  }
}
.glyph-policy-lifecycle {
  &:before {
    content: $glyph-policy-lifecycle; 
  }
}
.glyph-policy-masking {
  &:before {
    content: $glyph-policy-masking; 
  }
}
.glyph-policy-validation {
  &:before {
    content: $glyph-policy-validation; 
  }
}
.glyph-policy-external {
  &:before {
    content: $glyph-policy-external; 
  }
}
.glyph-sensor-internal {
  &:before {
    content: $glyph-sensor-internal; 
  }
}
.glyph-sensor-group {
  &:before {
    content: $glyph-sensor-group; 
  }
}
.glyph-sensor-external {
  &:before {
    content: $glyph-sensor-external; 
  }
}
.glyph-process-old {
  &:before {
    content: $glyph-process-old; 
  }
}
.glyph-data-flow {
  &:before {
    content: $glyph-data-flow; 
  }
}
.glyph-dataprocessing {
  &:before {
    content: $glyph-dataprocessing; 
  }
}
.glyph-processItem {
  &:before {
    content: $glyph-processItem; 
  }
}
.glyph-strategy {
  &:before {
    content: $glyph-strategy; 
  }
}
.glyph-initiative {
  &:before {
    content: $glyph-initiative; 
  }
}
.glyph-initiative-objective {
  &:before {
    content: $glyph-initiative-objective; 
  }
}
.glyph-usecase {
  &:before {
    content: $glyph-usecase; 
  }
}
.glyph-usecase-objective {
  &:before {
    content: $glyph-usecase-objective; 
  }
}
.glyph-marketplace {
  &:before {
    content: $glyph-marketplace; 
  }
}
.glyph-dataproduct {
  &:before {
    content: $glyph-dataproduct; 
  }
}
.glyph-dataproduct-ai {
  &:before {
    content: $glyph-dataproduct-ai; 
  }
}
.glyph-cdp {
  &:before {
    content: $glyph-cdp; 
  }
}
.glyph-domain1 {
  &:before {
    content: $glyph-domain1; 
  }
}
.glyph-tag-hierarchy {
  &:before {
    content: $glyph-tag-hierarchy; 
  }
}
.glyph-multi-value {
  &:before {
    content: $glyph-multi-value; 
  }
}
.glyph-api {
  &:before {
    content: $glyph-api; 
  }
}
.glyph-richtext {
  &:before {
    content: $glyph-richtext; 
  }
}
.glyph-text {
  &:before {
    content: $glyph-text; 
  }
}
.glyph-number {
  &:before {
    content: $glyph-number; 
  }
}
.glyph-time-series {
  &:before {
    content: $glyph-time-series; 
  }
}
.glyph-ddl {
  &:before {
    content: $glyph-ddl; 
  }
}
.glyph-csv-file {
  &:before {
    content: $glyph-csv-file; 
  }
}
.glyph-file-empty {
  &:before {
    content: $glyph-file-empty; 
  }
}
.glyph-file {
  &:before {
    content: $glyph-file; 
  }
}
.glyph-field {
  &:before {
    content: $glyph-field; 
  }
}
.glyph-json-file {
  &:before {
    content: $glyph-json-file; 
  }
}

