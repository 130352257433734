:root {
    --date-range-picker-text-color: var(--color-gray-800);
    --date-range-picker-margin-top: 10px;

    --date-range-picker-control-content-height: 35px;
    --date-range-picker-control-content-border-radius: 13px;

    --date-range-picker-button-margin: -5px;

    --date-range-picker-input-width: 95%;
    --date-range-picker-input-to-margin-left: 20.2px;
    --date-range-picker-from-to-margin-left: 13px;

    --date-range-picker-input-container-display: flex;
    --date-range-picker-input-container-align-items: center;
    --date-range-picker-input-container-width: 100%;

    --date-range-picker-form-control-margin-right: 8px;
    --date-range-picker-form-control-width: 137px;
    --date-range-picker-form-control-margin-left: auto;
    --date-range-picker-form-control-display: flex;
    --date-range-picker-form-control-align-items: center;
}
