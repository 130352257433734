:root {
    --radio-button-icon-background-color-selected-resting: var(
        --color-brand-default
    );
    --radio-button-icon-background-color-selected-hover: var(
        --color-brand-dark
    );
    --radio-button-icon-background-color-selected-click: var(
        --color-brand-light
    );
    --radio-button-icon-background-color-selected-disabled: var(
        --color-brand-light
    );

    --radio-button-icon-background-color-unselected-resting: var(
        --color-neutral-light
    );
    --radio-button-icon-background-color-unselected-hover: var(
        --color-neutral-light
    );
    --radio-button-icon-background-color-unselected-click: var(
        --color-neutral-light
    );
    --radio-button-icon-background-color-unselected-disabled: var(
        --color-neutral-light
    );

    --radio-button-icon-background-color-indeterminate-resting: var(
        --color-brand-default
    );
    --radio-button-icon-background-color-indeterminate-hover: var(
        --color-brand-dark
    );
    --radio-button-icon-background-color-indeterminate-click: var(
        --color-brand-light
    );
    --radio-button-icon-background-color-indeterminate-disabled: var(
        --color-brand-light
    );

    --radio-button-background-transparent: color-mix(
        in srgb,
        var(--color-brand-default),
        transparent var(--transparency-background-hover)
    );

    --radio-button-gap-size: var(--gap-gp-m);
    --radio-button-opacity-disabled: 0.5;

    --radio-button-icon-size-default: var(--icon-size-ic-m);
    --radio-button-icon-size-small: var(--icon-size-ic-s);

    --radio-button-icon-padding-right-default: var(--padding-pd-m);
    --radio-button-icon-padding-right-small: var(--padding-pd-s);

    --radio-button-content-size-default: var(--typography-fontsize-m);
    --radio-button-content-size-small: var(--typography-fontsize-s);
}
