:root {
    --breadcrumb-foreground-text-default: var(--color-neutral-darkest);
    --breadcrumb-foreground-text-light: var(--color-neutral-dark);
    --breadcrumb-foreground-text-on-hover: var(--color-brand-default);
    --breadcrumb-foreground-separator-default: var(--color-neutral-dark);

    --breadcrumb-gap-large: var(--gap-gp-m);
    --breadcrumb-gap-sm: var(--gap-gp-s);

    --breadcrumb-font-size-large: var(--typography-fontsize-s);
    --breadcrumb-font-size-m: var(--typography-fontsize-xs);
    --breadcrumb-font-size-s: var(--typography-fontsize-xxs);

    --breadcrumb-font-weight-large: var(--typography-fontweight-standard);
    --breadcrumb-font-weight-m: var(--typography-fontweight-standard);
    --breadcrumb-font-weight-s: var(--typography-fontweight-standard);

    --breadcrumb-line-height-large: 16px;
    --breadcrumb-line-height-m: normal;
    --breadcrumb-line-height-s: normal;

    --breadcrumb-separator-font-size-large: 12px;
    --breadcrumb-separator-font-size-m: 10px;
    --breadcrumb-separator-font-size-s: 8px;
}
