$google-font-path: '/fonts/google' !default;
$default-unicode-range:
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;

/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100 950;
    src:
        local('Montserrat-VariableFont_wght'),
        url('#{$google-font-path}/Montserrat-VariableFont_wght.ttf')
            format('truetype');
    unicode-range: $default-unicode-range;
}
