@use 'mixins/mixin-flex' as *;
@use 'base/text' as text;
@use 'graphical-colors.global' as *;
@use 'palette';

app-data-knowledge-studio {
    flex: 1;
}

//#region every z-index, ordered
app-diagram-designer {
    // lowest possible - but displayed when empty, so not critical
    .background-ghost {
        z-index: 0;
    }

    .surface-container {
        display: flex;
        width: 100%;
        height: 100%;
    }
}
//#endregion

dxy-graphical-toolbar.diagram-designer {
    .diagram-edge-alias-text-input dxy-graphical-text-input {
        display: flex;
        height: 30px;
        input {
            max-width: 100px;
            border: none;
        }
    }
}

// for draggable asset panel items, spotlight result items, preview panel linked-objects items
.app-diagram-asset-drag-preview {
    padding: 8px 30px 8px 8px;
    min-width: 250px;
    width: auto !important;
    height: auto !important;
    box-shadow: var(--color-shadow-any-neutral);
    .actionTools {
        display: none;
    }
}
.diagram-external-asset {
    cursor: move;
}
.diagram-external-asset-dragging {
    &:not(.custom-preview),
    &.custom-preview .object {
        @extend .app-diagram-asset-drag-preview;
        background-color: white;
    }
    &.custom-preview {
        .link,
        .object {
            transform: translate(-20px, -130px);
        }
        .link {
            width: 0;
            height: 50px;
            border-left: 2px solid $graphical-color-gray;
            margin-left: 50%;
        }
    }
}

.dxy-graphical-toolbar--mat-menu,
dxy-graphical-toolbar {
    &.diagram-designer {
        .glyph-color-white {
            color: var(--color-foreground-any-tertiary);
        }
        .nano-pick {
            font-size: text.$tiny-text-size !important;
        }
        .graphical-color.soften {
            opacity: 0.5;
        }
    }
}

app-diagram-edge-style-selector,
.mat-menu-panel.app-diagram-edge-style-selector--mat-menu {
    path {
        fill: none;
        stroke: black;
        stroke-width: 2;
        pointer-events: none;
    }
}

//#region line styles
// Those are names of LineStyle enum values
$line-style-dasharrays: (
    'line-style-dashed_2_2': 2 2,
    'line-style-dashed_3_3': 3 3,
    'line-style-dashed_7_2': 7 2,
    'line-style-dashed_2_2_6_2': 2 2 6 2,
);

.app-diagram-edge-style-selector--mat-menu .select-item svg {
    @each $name, $dasharray in $line-style-dasharrays {
        &.#{$name} path {
            stroke-dasharray: $dasharray;
        }
    }
}
//#endregion

app-diagram-designer {
    --resize-margin: 0px; // set by diagram-designer
    --link-cursor-url: url(/images/cursors/cursor-link-20px.png); // changed to a svg blob url by diagram-designer
    flex-grow: 1;
    display: flex;
    @include flexV();

    dxy-graphical-controls {
        // to hide the vertical scrollbar appearing
        // when dragging a cdkDrag from a list to the surface
        overflow: hidden;
        width: 100%;
        height: 100%;

        background-color: var(--color-background-any-tertiary);

        // Additional margin in diagrams for bottom right toolbar to prevent overlap with Pendo's Resource center
        .bottom-right-toolbar {
            margin-right: 50px;
        }
    }

    &.adding-frame-with-lasso {
        cursor: crosshair;
    }

    app-diagram-element.selected,
    app-diagram-element.port-editing {
        // hide source ports when element is selected or hovered for connection, or being dragged
        .source-port {
            display: none;
        }
    }

    // link label (wrapper)
    .link-label {
        display: flex !important;
        flex-wrap: nowrap;
        align-items: center;
        box-shadow: unset !important;
        background: var(--secondary-background-color) !important;
        line-height: 20px !important;

        .link-label-debug {
            position: absolute;
            top: 10px;
        }
    }

    // foreign-key links label text is clickable
    .link-label-text.edge-type-fk {
        cursor: pointer;
    }

    app-diagram-element {
        &.selected,
        &.highlighted {
            outline: 2px solid palette.$reflex-40;
            outline-offset: 6px;
        }
    }

    //#region while dragging a frame
    // applied on frame-contained nodes
    .in-dragged-frame {
        pointer-events: none;
    }
    &.debug.show-rects .in-dragged-frame {
        border: 1px solid purple;
    }
    // applied on frame-contained nodes content
    .canvas-child {
        position: absolute;
        background-color: white;
        padding: 8px;
    }
    // for to hide links with a smooth & fast transition on frame-drag start
    .hidding {
        opacity: 0;
        transition: opacity 0.1s;
    }
    //#endregion

    [mini-map-content] {
        background-color: white;
        &.full-size {
            height: 100%;
            width: 100%;
        }
        .mini-view-container {
            margin: auto; // centered
            overflow: visible !important;
            border: none;
            border-radius: 0 !important;
        }
    }

    .diagram-asset-panel-item {
        &.cdk-drag-preview {
            @extend .app-diagram-asset-drag-preview;
        }
        .foreign-key-item--isFunctionalOnly .glyph {
            color: palette.$green-50 !important;
        }
    }

    .selection-bounding-box {
        display: none;
        position: absolute;
        pointer-events: none;
        outline: 2px solid palette.$reflex-40;
        outline-offset: 6px;

        &.active {
            display: unset;
        }
    }

    .background-ghost {
        position: absolute;
        inset: 50% auto auto 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        width: 100%;
        color: palette.$reflex-40;
        cursor: default !important;

        > .image-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            img,
            dxy-lottie-anim {
                width: 40vw;
                margin-bottom: 20px;
            }
        }
    }
}

.diagram-link-explorer app-entity-linked-objects {
    background-color: white;
    box-shadow: var(--color-shadow-any-neutral);
    border-radius: 3px;
    overflow-y: hidden;
    .panel-content-body {
        max-height: 100%;
        dxy-tabs-header {
            max-height: 100%;
            .mat-tab-group {
                overflow: unset;
            }
            .cdk-drop-list {
                overflow-y: scroll;
            }
            .no-item-title {
                margin-top: 0;
            }
        }
    }
}

app-diagram-designer {
    div.diagramming {
        .cdk-drag.diagram-external-asset {
            // hide the dragged item - its ghost is still visible
            display: none;
        }
        app-diagram-element {
            // hide source ports when connecting to the target node
            &.target-connecting .source-port {
                opacity: 0;
            }

            // port-handle (to be round and grow as the source-port)
            .pm-handle.ph-custom {
                border-radius: 50%;
                width: 100% !important;
                height: 100% !important;
                &:hover {
                    transform: scale(200%);
                }
            }
        }
    }

    // &.debug div.diagramming {
    // }
}
