.dxy-menu-list {
    display: flex;
    flex-direction: column;
    background-color: var(--menus-background-color);
    border-radius: var(--menus-radius-large);
    box-shadow: var(--menus-shadow);
    max-height: var(--menus-max-height);
    overflow-x: hidden;
    overflow-y: auto;

    &.hidden {
        display: none;
    }
}

.bordered {
    border: 1px solid var(--menu-border-color-bordered);
}
