:root {
    --checkbox-icon-background-color-checked-resting: var(
        --color-brand-default
    );
    --checkbox-icon-background-color-checked-hover: var(--color-brand-dark);
    --checkbox-icon-background-color-checked-click: var(--color-brand-light);
    --checkbox-icon-background-color-checked-disabled: var(--color-brand-light);

    --checkbox-icon-background-color-unchecked-resting: var(
        --color-neutral-light
    );
    --checkbox-icon-background-color-unchecked-hover: var(
        --color-neutral-light
    );
    --checkbox-icon-background-color-unchecked-click: var(
        --color-neutral-light
    );
    --checkbox-icon-background-color-unchecked-disabled: var(
        --color-neutral-light
    );

    --checkbox-icon-background-color-indeterminate-resting: var(
        --color-brand-default
    );
    --checkbox-icon-background-color-indeterminate-hover: var(
        --color-brand-dark
    );
    --checkbox-icon-background-color-indeterminate-click: var(
        --color-brand-light
    );
    --checkbox-icon-background-color-indeterminate-disabled: var(
        --color-brand-light
    );

    --checkbox-background-transparent-hover: rgba(61, 92, 254, 0.1);
    --checkbox-background-transparent-click: rgba(61, 92, 254, 0.1);

    --checkbox-opacity-disabled: 0.5;

    --checkbox-icon-size-default: 20px;
    --checkbox-icon-size-small: 16px;

    --checkbox-icon-padding-right-default: var(--padding-pd-m);
    --checkbox-icon-padding-right-small: var(--padding-pd-s);

    --checkbox-content-size-default: var(--typography-fontsize-m);
    --checkbox-content-size-small: var(--typography-fontsize-xs);
}
