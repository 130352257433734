:root {
    --date-picker-form-control-width: 272px;
    --date-picker-form-control-margin-left: 10px;

    --date-picker-selected-bg-color: var(--buttons-background-primary-default-resting);

    --date-picker-button-margin: -5px;

    --date-picker-input-width: 95%;

    --date-picker-icon-padding: 8px;
    --date-picker-icon-selected-bg: var(--buttons-background-primary-default-resting);
    --date-picker-icon-border-radius: 50%;
}
