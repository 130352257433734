:root {
    --messages-background-transparency: 90%;
    --messages-background-default: color-mix(
        in srgb,
        var(--color-neutral-default),
        transparent var(--messages-background-transparency)
    );
    --messages-background-default-alt: var(--color-background-any);
    --messages-background-info: color-mix(
        in srgb,
        var(--color-info-default),
        transparent var(--messages-background-transparency)
    );
    --messages-background-ai: color-mix(
        in srgb,
        var(--color-ai-default),
        transparent var(--messages-background-transparency)
    );
    --messages-background-success: color-mix(
        in srgb,
        var(--color-success-default),
        transparent var(--messages-background-transparency)
    );
    --messages-background-warning: color-mix(
        in srgb,
        var(--color-warning-default),
        transparent var(--messages-background-transparency)
    );
    --messages-background-danger: color-mix(
        in srgb,
        var(--color-destructive-default),
        transparent var(--messages-background-transparency)
    );

    --messages-accent-color-default: var(--color-neutral-darkest);
    --messages-accent-color-info: var(--color-info-default);
    --messages-accent-color-ai: var(--color-ai-default);
    --messages-accent-color-success: var(--color-success-default);
    --messages-accent-color-warning: var(--color-warning-default);
    --messages-accent-color-danger: var(--color-destructive-default);

    --messages-timer-border-radius: 99px;
    --messages-gap-m: var(--gap-gp-l);
    --messages-gap-s: var(--gap-gp-m);
    --messages-gap-xs: var(--gap-gp-s);

    --messages-icon-size: var(--icon-size-ic-l);

    --messages-radius-xl: var(--radius-rd-xl);

    --toast-message-shadow: var(--shadow-neutral);
}
