:root {
    --icon-card-foreground-text-default: var(--color-neutral-darker);
    --icon-card-foreground-separtor: var(--color-neutral-transparent-40);
    --icon-card-background-card-default: var(--color-neutral-transparent-10);
    --icon-card-size-xs: 16px;
    --icon-card-size-s: 44px;
    --icon-card-size-m: 64px;
    --icon-card-size-l: 72px;
    --icon-card-size-xl: 80px;
    --icon-card-size-xxl: 108px;
    --icon-card-spacing-small: var(--gap-gp-xs);
    --icon-card-spacing-medium: var(--gap-gp-s);
    --icon-card-spacing-large: var(--gap-gp-m);
    --icon-card-radius-small: var(--radius-rd-l);
    --icon-card-radius-large: var(--radius-rd-xl);
}
