:root {
    --avatar-background-default: var(--color-gray-100);
    --avatar-color-default: var(--color-gray-900);

    --avatar-color-reverse: white;

    --avatar-radius-square: var(--radius-rd-s);

    --avatar-fontsize-s: var(--typography-fontsize-xs);
    --avatar-fontsize-m: var(--typography-fontsize-s);
    --avatar-fontsize-l: var(--typography-fontsize-m);

    --avatar-size-s: var(--pixels-24);
    --avatar-size-m: var(--pixels-32);
    --avatar-size-l: var(--pixels-48);

    --avatarGroup-marginInlineStart: -5px;
    --avatarGroup-borderSize: 2px;
    --avatarGroup-borderColor: white;
}
