@use 'base/text' as text;
@use 'palette';

.dg5-entity-card {
    width: 100%;
    display: flex;

    dxy-meta-object-type-card {
        margin-right: 5px;
    }

    &.mini-card {
        height: 22px;
        .entity-data-container {
            align-self: center;
        }
        .img-wrapper,
        .glyph {
            height: 20px;
            line-height: 20px;
            text-align: center;
            margin-right: 3px;
            margin-left: 3px;
        }
    }

    .entity-data-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        white-space: nowrap;
    }

    .entity-names {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
        width: 100%;
        overflow: hidden;
        &.no-height {
            .entity-name {
                line-height: unset;
                height: unset;
            }
            .secondary-name {
                line-height: unset;
                .entity-name {
                    line-height: unset;
                }
            }
        }

        .entity-name {
            font-size: text.$normal-text-size;
            line-height: 20px;
            height: 20px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            vertical-align: middle;
        }

        .primary-name {
            display: flex;
            color: palette.$violet-50;
            margin-right: 10px;
        }
        .secondary-name {
            display: flex;
            padding: 1px 5px;
            border-radius: 3px;
            background-color: var(--color-background-any-secondary);
            font-size: text.$tiny-text-size;
            line-height: 14px;
            .entity-name {
                display: unset;
                height: unset;
                font-size: text.$tiny-text-size;
                line-height: 14px;
            }
        }
    }
}
