:root {
    --multiselect-trigger-gap: var(--gap-gp-m);
    --multiselect-trigger-lineheight: 24px;
    --multiselect-search-bar-padding: var(--padding-pd-m) var(--padding-pd-l);
    --multiselect-search-bar-gap: var(--gap-gp-m);
    --multiselect-tab-header-padding: var(--padding-pd-s) var(--padding-pd-l)
        var(--padding-pd-m) var(--padding-pd-l);
    --multiselect-tab-header-fontsize: var(--typography-fontsize-s);
    --multiselect-tab-header-fontweight: 400;
    --multiselect-tab-header-lineheight: var(--typography-lineheight-m);
    --multiselect-tab-header-inactive-color: var(--color-neutral-default);
    --multiselect-tab-header-active-border: 1px solid #000;
    --multiselect-tab-header-selected-gap: var(--gap-gp-xs);
    --multiselect-tab-header-selected-counter-padding: var(--padding-pd-xs)
        var(--padding-pd-s);
}
