:root {
    --calendar-width: 296px;
    --calendar-padding: var(--pixels-12, 12px);
    --calendar-display: flex;
    --calendar-direction: column;
    --calendar-align-items: center;
    --calendar-gap: var(--pixels-08, 10px);

    --calendar-header-font-weight: bold;
    --calendar-header-margin-bottom: var(--pixels-08);
    --calendar-header-text-align: center;
    --calendar-header-color: var(--color-gray-800);
    --calendar-header-justify-content: space-between;
    --calendar-header-align-items: center;
    --calendar-header-display: flex;

    --calendar-left-margin: 7px;
    --calendar-right-margin: 7px;
    --calendar-arrow-bg: none;
    --calendar-arrow-border: none;

    --calendar-weekdays-text-align: center;
    --calendar-weekdays-color: var(--color-gray-600);

    --calendar-days-grid-columns: repeat(7, 1fr);
    --calendar-days-gap: 5px 18px;
    --calendar-days-margin-top: var(--pixels-08-rem);
    --calendar-days-padding: var(--pixels-06, 6px);
    --calendar-days-border-radius: 37%;
    --calendar-days-cursor: pointer;
    --calendar-days-font-size: var(--typography-fontsize-s);
}
