:root {
    --object-card-border-radius-default: var(--radius-rd-xl);
    --object-card-border-color-resting: var(--color-neutral-lighter);
    --object-card-background-color-resting: var(--color-neutral-negative);
    --object-card--header-box-shadow-resting:
        0 10px 50px -10px rgba(0, 17, 108, 0.02),
        0 5px 25px -5px rgba(0, 17, 108, 0.02),
        0 3px 12px -1px rgba(0, 17, 108, 0.02),
        0 0px 2px 0px rgba(0, 17, 108, 0.02);

    --object-card-border-color-hover: #cfd1d8;
    --object-card-box-shadow-hover:
        0 20px 100px -20px rgba(0, 17, 108, 0.03),
        0 10px 50px -10px rgba(0, 17, 108, 0.03),
        0 5px 25px -5px rgba(0, 17, 108, 0.03),
        0 1px 5px -1px rgba(0, 17, 108, 0.03);
}
