:root {
    --field-label-section-padding: var(--padding-pd-m) 0 0 var(--padding-pd-m);
    --field-label-subsection-padding-bottom: var(--padding-pd-s);
    --field-label-section-gap: var(--gap-gp-xs);
    --field-label-padding: var(--padding-pd-s) 0;
    --field-label-color: var(--color-neutral-darker);
    --field-label-font-size: var(--typography-fontsize-s);
    --field-label-line-height: var(--typography-lineheight-m);
    --field-label-actions-gap: var(--gap-gp-m);
    --field-sublabel-font-size: var(--typography-fontsize-xxs);
    --field-sublabel-color: var(--color-neutral-darker);
    --field-value-section-gap: var(--gap-gp-m);
    --field-component-padding-stacked: var(--padding-pd-l) var(--padding-pd-xxl)
        var(--padding-pd-xl) var(--padding-pd-xxl);
    --field-component-padding-inline: var(--padding-pd-l) var(--padding-pd-xxl);
    --field-label-padding-vertical-inline: var(--padding-pd-m);
    --field-label-padding-top-inline: var(--padding-pd-l);
}
