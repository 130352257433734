:root {
    --color-gray-10p: #abadba1a;
    --color-gray-20p: #abadba33;
    --color-gray-40p: #abadba66;
    --color-gray-050: #f8f7f8ff;
    --color-gray-100: #f1f1f4ff;
    --color-gray-200: #e3e4e8ff;
    --color-gray-300: #cfd1d8ff;
    --color-gray-400: #bcbec8ff;
    --color-gray-500: #abaebbff;
    --color-gray-600: #9498a8ff;
    --color-gray-700: #818598ff;
    --color-gray-800: #676b7eff;
    --color-gray-900: #001030ff;

    --color-reflex-10p: #407dff1a;
    --color-reflex-20p: #4d7fff33;
    --color-reflex-050: #f3f7ffff;
    --color-reflex-100: #e6edffff;
    --color-reflex-200: #c9d8ffff;
    --color-reflex-300: #a8c5feff;
    --color-reflex-400: #6991f7ff;
    --color-reflex-500: #3d5cffff;
    --color-reflex-600: #2645e8ff;
    --color-reflex-700: #112cbbff;
    --color-reflex-800: #001275ff;
    --color-reflex-900: #001030ff;

    --color-blue-10p: #00b2ff1a;
    --color-blue-20p: #29bfff33;
    --color-blue-050: #f0fbffff;
    --color-blue-100: #e0f3ffff;
    --color-blue-200: #c2eeffff;
    --color-blue-300: #9ee2ffff;
    --color-blue-400: #69ccf7ff;
    --color-blue-500: #00a8f0ff;
    --color-blue-600: #0092dbff;
    --color-blue-700: #006bb8ff;
    --color-blue-800: #004f99ff;
    --color-blue-900: #00306bff;

    --color-turquoise-10p: #00fdd81a;
    --color-turquoise-20p: #0bdabb33;
    --color-turquoise-050: #e9fbf8ff;
    --color-turquoise-100: #d9fcf7ff;
    --color-turquoise-200: #bdf8efff;
    --color-turquoise-300: #77f8e5ff;
    --color-turquoise-400: #36ecd1ff;
    --color-turquoise-500: #00e0bfff;
    --color-turquoise-600: #00c7a9ff;
    --color-turquoise-700: #00a38bff;
    --color-turquoise-800: #00806dff;
    --color-turquoise-900: #00574aff;

    --color-grass-10p: #1ae5801a;
    --color-grass-20p: #1ae58033;
    --color-grass-050: #ebfdf4ff;
    --color-grass-100: #d6fae8ff;
    --color-grass-200: #91f3c2ff;
    --color-grass-300: #1ae580ff;
    --color-grass-400: #00cc66ff;
    --color-grass-500: #00a352ff;
    --color-grass-600: #008040ff;
    --color-grass-700: #0e693aff;
    --color-grass-800: #0b562fff;
    --color-grass-900: #083d22ff;

    --color-purple-10p: #ee2eff1a;
    --color-purple-20p: #e705ff33;
    --color-purple-050: #fdf0feff;
    --color-purple-100: #fbddfdff;
    --color-purple-200: #f5b1fbff;
    --color-purple-300: #ed72f8ff;
    --color-purple-400: #db3eeaff;
    --color-purple-500: #af00bfff;
    --color-purple-600: #9a00a8ff;
    --color-purple-700: #7a0085ff;
    --color-purple-800: #62006bff;
    --color-purple-900: #410047ff;

    --color-violet-10p: #a257ff1a;
    --color-violet-20p: #8f33ff33;
    --color-violet-050: #f4eefcff;
    --color-violet-100: #eadafdff;
    --color-violet-200: #d3b6f7ff;
    --color-violet-300: #b579ffff;
    --color-violet-400: #8d3df5ff;
    --color-violet-500: #7300ffff;
    --color-violet-600: #5c00ccff;
    --color-violet-700: #450099ff;
    --color-violet-800: #390080ff;
    --color-violet-900: #270057ff;

    --color-magenta-10p: #ff335f1a;
    --color-magenta-20p: #ff145333;
    --color-magenta-050: #ffedf1ff;
    --color-magenta-100: #ffdae4ff;
    --color-magenta-200: #ffb2caff;
    --color-magenta-300: #ff83acff;
    --color-magenta-400: #f65188ff;
    --color-magenta-500: #e50060ff;
    --color-magenta-600: #b2004aff;
    --color-magenta-700: #800035ff;
    --color-magenta-800: #570024ff;
    --color-magenta-900: #520022ff;

    --color-yellow-10p: #ffcc001a;
    --color-yellow-20p: #ffcc0033;
    --color-yellow-050: #fffcf2ff;
    --color-yellow-100: #fffae4ff;
    --color-yellow-200: #fff4c6ff;
    --color-yellow-300: #ffe98fff;
    --color-yellow-400: #ffdc52ff;
    --color-yellow-500: #ffcc00ff;
    --color-yellow-600: #f0a400ff;
    --color-yellow-700: #c77e00ff;
    --color-yellow-800: #804d00ff;
    --color-yellow-900: #663300ff;

    --color-orange-10p: #ff73001a;
    --color-orange-20p: #ff730033;
    --color-orange-050: #fff6f2ff;
    --color-orange-100: #ffede4ff;
    --color-orange-200: #ffcaa8ff;
    --color-orange-300: #ffac75ff;
    --color-orange-400: #ff9147ff;
    --color-orange-500: #ff7300ff;
    --color-orange-600: #db6300ff;
    --color-orange-700: #a34900ff;
    --color-orange-800: #803300ff;
    --color-orange-900: #5c1c00ff;

    --color-lime-10p: #ace5001a;
    --color-lime-20p: #b4f00033;
    --color-lime-050: #fafef0ff;
    --color-lime-100: #f5fce1ff;
    --color-lime-200: #eafbb6ff;
    --color-lime-300: #daf877ff;
    --color-lime-400: #c9f320ff;
    --color-lime-500: #bfe500ff;
    --color-lime-600: #95c200ff;
    --color-lime-700: #739900ff;
    --color-lime-800: #4d6b00ff;
    --color-lime-900: #2c3d00ff;

    --color-green-10p: #5de51a1a;
    --color-green-20p: #5de51a33;
    --color-green-050: #f4fdefff;
    --color-green-100: #e8fbdeff;
    --color-green-200: #cef7b9ff;
    --color-green-300: #9ef075ff;
    --color-green-400: #81eb4cff;
    --color-green-500: #58d818ff;
    --color-green-600: #3fbc15ff;
    --color-green-700: #2c9911ff;
    --color-green-800: #217d0eff;
    --color-green-900: #042704ff;

    --color-transparent: #ffffff00;
    --color-opacity-8: #011eb714;
    --color-opacity-20: rgba(61, 92, 254, 0.2);
    --color-opacity-30: rgba(0, 0, 0, 0.3);

    --pixels-none: 0px;
    --pixels-none-rem: 0rem;
    --pixels-01: 1px;
    --pixels-01-rem: 0.0625rem;
    --pixels-02: 2px;
    --pixels-02-rem: 0.125rem;
    --pixels-04: 4px;
    --pixels-04-rem: 0.25rem;
    --pixels-06: 6px;
    --pixels-06-rem: 0.375rem;
    --pixels-08: 8px;
    --pixels-08-rem: 0.5rem;
    --pixels-12: 12px;
    --pixels-12-rem: 0.75rem;
    --pixels-16: 16px;
    --pixels-16-rem: 1rem;
    --pixels-24: 24px;
    --pixels-24-rem: 1.5rem;
    --pixels-32: 32px;
    --pixels-32-rem: 2rem;
    --pixels-48: 48px;
    --pixels-48-rem: 3rem;
    --pixels-64: 64px;
    --pixels-64-rem: 4rem;
    --pixels-round: 999px;
    --pixels-round-rem: 62.4375rem;

    --typography-fontface-default: 'Montserrat';
    --typography-fontweight-default: 'Medium';
    --typography-fontweight-bold: 700;

    --typography-lineheight-s: 12px;
    --typography-lineheight-s-rem: 0.75rem;
    --typography-lineheight-m: 16px;
    --typography-lineheight-m-rem: 1rem;
    --typography-lineheight-l: 20px;
    --typography-lineheight-l-rem: 1.25rem;

    --typography-fontsize-xxs: 9px;
    --typography-fontsize-xs: 11px;
    --typography-fontsize-xs-rem: 0.6875rem;
    --typography-fontsize-s: 13px;
    --typography-fontsize-s-rem: 0.8125rem;
    --typography-fontsize-m: 15px;
    --typography-fontsize-m-rem: 0.9375rem;
    --typography-fontsize-l: 18px;
    --typography-fontsize-l-rem: 1.125rem;
    --typography-fontsize-xl: 22px;
    --typography-fontsize-xl-rem: 1.375rem;
    --typography-fontsize-xxl: 30px;
    --typography-fontsize-xxl-rem: 1.875rem;
}
